import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThemeContext } from '../Context/ThemeContext';
import qvaniBlueBlogo from '../assets/Images/LogoBlue.png'
import PropTypes from 'prop-types';
import './CustomModal.css';
import { RxCross1 } from "react-icons/rx";

const OkButton = styled.button`
border-radius: 46px;
padding: 13px 30px;
font-weight: 500;
border: none;
background: #1476FF;
font-size: 16px;
color: white;
transition: 0.5s;
:hover{
    transition: 0.5s;
    opacity: 0.7;
}
`
const KycButton = styled.button`
border-radius: 46px;
padding: 13px 30px;
font-weight: 500;
border: none;
background: #1476FF;
font-size: 16px;

color: white;
transition: 0.5s;
:hover{
    transition: 0.5s;
    opacity: 0.7;
}
`
const CancelButton = styled.button`
border-radius: 46px;
    padding: 13px 30px;
    font-weight: 500;
    border: 1px solid rgba(210, 43, 43, 1);
    background: rgba(210, 43, 43, 0.15);
    font-size: 16px;
    color: rgba(210, 43, 43, 1);
    transition: all 0.5s ease 0s;
`
const Div = styled.div`
min-height: 3.3rem;
padding: 0 15px;
display: flex;
flex-direction: column;
align-items: center;
font-size: 18px;
text-align: center;
justify-content: center;
// font-weight: 500;
word-break: break-word;
`
const CustomModal = ({ show, handleClose, navigateLink, data, isKycNeed= false }) => {
    const { isDarkTheme } = useThemeContext();
    const navigate = useNavigate();
    const location = useLocation();

    const handleCloseFun = () => {
        handleClose(false);
        if (navigateLink?.length > 0) {
            navigate(navigateLink);
        }
    }

    const handleCloseFun1 = () => {
        navigate("/dashboard/settings#kyc-levels");
    }

    const handleCancelFun = () => {
        handleClose(false);
    }

    return (
        <Modal show={show} className={`response-modal ${location?.pathname === '/signup' && 'background_color_change'}`}>
            {location?.pathname === '/signup' && <div className='qvaniLogoTitle mt-3 ms-3'><img src={qvaniBlueBlogo} alt="qvaniBlueBlogo" /></div>}
            <Modal.Body style={{ color: `${isDarkTheme ? 'white' : 'black'}`, backgroundColor: `${isDarkTheme ? 'black' : 'white'}`, padding: '7px', borderRadius: '30px' }}>
                <div className='text-end mt-2 mb-2'>
                    <button className='border-0 bg-transparent' onClick={() => handleCancelFun()}> <RxCross1 color='#6F7182' size={22} /> </button>
                </div>
                <Div>
                    {data === 'Some Error Occured' ? <>
                        <p className='mb-0 fw-bold text-center'>The Connection has timed out...</p>
                        <p className='mb-0 fw-bold text-center'>Please try again later</p>
                        <ul>
                            <li>If you're unable to load the page, check your computer's network connection.</li>
                            <li>Check if your computer or network is protected by firebase or proxy.</li>
                        </ul>
                    </> : data}
                </Div>
                <div className="d-flex justify-content-center align-items-center my-2 gap-4">
                    {isKycNeed ? <>
                        <KycButton onClick={() => handleCloseFun1()}>Proceed to KYC</KycButton>
                        <CancelButton onClick={() => handleCloseFun()}>Cancel</CancelButton>
                    </> : 
                    <OkButton onClick={() => handleCloseFun()}>OK</OkButton>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

CustomModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    navigateLink: PropTypes.string,
    data: PropTypes.any.isRequired,
};

export default CustomModal;
