// import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
// import { browserName } from 'react-device-detect';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { API_URLs} from '../Utils/ApiUrls';
// import { requestForToken } from '../firebase';
// import { useThemeContext } from './ThemeContext';

// export const AuthContextHook = createContext();
// export const useAuthContextHook = () => {
//     return useContext(AuthContextHook);
// }
// export default function AuthContext({ children }) {
//     const navigate = useNavigate();    

//     const [step, setStep] = useState(0);
//     const { country, ipAddress } = useThemeContext();
//     const [showModal, setShowModal] = useState(false);
//     const [modalData, setModalData] = useState(null);
//     const [navigateLink, setNavigateLink] = useState('');
//     const handleShowModal = (data) => {
//         setModalData(data);
//         setShowModal(true);
//     };
//     const handleCloseModal = () => {
//         setShowModal(false)
//     };

//     const [IsLogedIn, setIsLogedIn] = useState(true);
//     const [userDetails, setUserDetails] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [isAuth, setisAuth] = useState(true);
//     const [cred, setCred] = useState([]);
//     const [apiLoader, setApiLoader] = useState(false);
//     const [userId, setUserId] = useState("")

//     //logout Api
//     const handleLogout = useCallback(async () => {
//         setApiLoader(true)
//         axios.post(API_URLs.logout,{
//             refreshToken: localStorage.getItem('jwtOut')
//         },{
//             headers: {
//                 accept: 'application/json',
//                 Authorization: `Bearer ${localStorage.getItem('jwt')}`
//             }
//         })
//         .then((res)=>{
//             setNavigateLink('/login')
//             navigate('/login')
//             setIsLogedIn(false);
//             setApiLoader(false)
//             setisAuth(false);
//             handleShowModal(res?.data?.msg ? res?.data?.msg : 'Logged out successfully');
//             localStorage.clear(); 
//         })
//         .catch((error)=>{
//             setApiLoader(false)
//             handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured');
//             setNavigateLink('');
//         })
//     }, [])

//     //signup api
//     const handleSignUp = useCallback(async (email, password, referralCode) => {
//         setCred({email, password, referralCode})
//         try {
//             let res = await axios.post(API_URLs.signUp, {
//                 email,
//                 password,
//                 ...(
//                     referralCode && {referralCode}
//                 )
//             })       
//             localStorage.setItem('jwt', res.data.tokens);
//             setStep(step+1)
//         } catch (error) {
//             setNavigateLink('');
//             handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occured");
//         }
//     }, [])

//     const handleOtpVerification = async (otp) => {
//         try {
//             let res = await axios.post(API_URLs.signUpOtp, {
//                 token: localStorage.getItem("jwt"),
//                 otp: otp
//             })
//             localStorage.setItem('jwt', res.data.tokens.registerAcccessToken.token);
//             handleShowModal(res?.data?.msg ? res?.data?.msg : 'Account Created Succesfully');
//             setNavigateLink('/signup/phone-verification');
//             setStep(0);
//         } catch (error) {
//             setNavigateLink(''); 
//             handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occured");
//         }
//     }


//     // const handleCreatePassword = async (password, cpassword) => {
//     //     try {
//     //         let res = await axios.post(API_URLs.signUpPassword, {
//     //             password: password,
//     //             cpassword: cpassword,
//     //             token: localStorage.getItem('jwt')
//     //         })            
//     //         setIsLogedIn(false);
//     //         setStep(step+1);
//     //         handleShowModal(res?.data?.msg ? res?.data?.msg : 'Account Created Succesfully');
//     //         setNavigateLink('/login');
//     //     } catch (error) {
//     //         setNavigateLink('');
//     //         handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occured");
//     //     }
//     // }

//     //login Api
//     const handleLogin = async (email, password) => {
//         setCred({email, password});
//         try {
//             let res = await axios.post(API_URLs.logIn, {
//                 email: email,
//                 password: password,
//             },{
//                 headers: {
//                     'device-address': country,
//                     'device-type': browserName,
//                     'ip-address': ipAddress
//                 }
//             })
//             if (res?.data?.isTwoFa) {
//                 localStorage.setItem('jwt', res.data?.responses?.token);
//                 setNavigateLink('/two-fa-login');
//                 if(res.data?.responses?.img){
//                     localStorage.setItem('qrcode',res.data?.responses?.img);
//                 }
//                 handleShowModal(res?.data?.msg ? res?.data?.msg : 'OTP sent successfully on your Email/Phone');
//             } else {
//                 localStorage.setItem('jwt', res.data?.tokens);
//                 setNavigateLink('/login/otp');
//                 handleShowModal(res?.data?.msg ? res?.data?.msg : 'OTP sent successfully on your Email/Phone');
//             }
//         } catch (error) {
//             handleShowModal(error?.response?.data?.message ? error.response?.data?.message : "Some Error occured")
//             setNavigateLink('');
//         }
//     }

//     //otp verification api
//     const handleLoginOTPVerification = async (otp) => {
//         try {
//             let res = await axios.post(API_URLs.loginVerify, {
//                 otp: otp,
//                 token: localStorage.getItem('jwt')
//             })
//             setUserId(res?.data?.user?._id)
//             localStorage.setItem('jwt', res.data?.tokens?.access?.token);
//             localStorage.setItem('jwtOut', res.data?.tokens?.refresh?.token)
//             requestForToken();

//             setUserDetails(res.data)

//             setIsLogedIn(true);
//             setisAuth(true);

//             if(localStorage.getItem('pending-route')?.length > 0){
//                 navigate(localStorage.getItem('pending-route'))
//             }
//             else{
//                 navigate('/dashboard/user-dashboard');
//             }

//         } catch (error) {
//             handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Otp verification failed or may be expired");
//             setNavigateLink('');
//             setisAuth(false);
//         }
//     }

//     //forgot Api
//     const handleForgotPassword = async (email) => {
//         try {
//             let res = await axios.post(API_URLs.forgot, {
//                 email: email
//             }) 
//             handleShowModal(res?.data?.msg ? res?.data?.msg : "Please check your mail to Reset password")
//             setNavigateLink('/login');
//         } catch (error) {
//             setNavigateLink('');
//             handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "No users found with this email");
//         }
//     }

//     //reset Api
//     const handleResetPassword = async (password, token) => {
//         try{       
//             let res = await axios.post(API_URLs.reset + '?token=' + token, {
//                 password: password,
//             })     
//             handleShowModal(res?.data?.msg ? res?.data?.msg :'Password Changed Successfully!!')
//             setNavigateLink('/login');
//         } catch (error) {
//             setNavigateLink('');
//             handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occured");
//         }
//     }

//     const getUserDetails = () => {
//         axios.get(API_URLs.getUser, {
//             headers: {
//                 'accept': 'application/json',
//                 "Authorization": `Bearer ${localStorage.getItem('jwt')}`
//             }
//         })
//             .then((res) => {
//                 setLoading(false)
//                 setIsLogedIn(true)
//                 setUserDetails(res.data)
//             })
//             .catch((error) => {
//                 setIsLogedIn(false)
//                 setLoading(false)
//             })
//     }

//     const handleVerifyTwoFa = async (otpValue) => {
//         try {
//             const res = await axios.post(API_URLs.verifyTwoFa, {
//                 token: localStorage.getItem('jwt'),
//                 otp: otpValue
//             }, {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('jwt')}`
//                 }
//             })

//             localStorage.setItem('jwt', res.data?.tokens?.access?.token);
//             localStorage.setItem('jwtOut', res.data?.tokens?.refresh?.token)
//             requestForToken();
//             setUserDetails(res.data)
//             localStorage.removeItem('qrcode');
//             navigate('/dashboard/user-dashboard');
//             setIsLogedIn(true);
//             setisAuth(true);
//         } catch (error) {

//             toast.error(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured", {
//                 position: toast.POSITION.TOP_CENTER
//             })
//         }
//     }

//     useEffect(() => {
//         getUserDetails()
//     }, [])
//     const exportableData = {
//         handleLogout,
//         getUserDetails,
//         handleSignUp,
//         handleOtpVerification,
//         handleLogin,
//         handleLoginOTPVerification,
//         handleForgotPassword,
//         handleResetPassword,
//         handleVerifyTwoFa,
//         IsLogedIn,
//         isAuth,
//         userDetails,
//         loading,
//         apiLoader,
//         userId,
//         step,
//         setStep,
//         showModal,
//         handleCloseModal,
//         navigateLink,
//         modalData,
//         cred
//     }

//     return (
//         <AuthContextHook.Provider value={{ ...exportableData }} >
//             {children}
//         </AuthContextHook.Provider>
//     )
// }


import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { browserName } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URLs } from '../Utils/ApiUrls';
import { requestForToken } from '../firebase';
import { useThemeContext } from './ThemeContext';

export const AuthContextHook = createContext();
export const useAuthContextHook = () => {
    return useContext(AuthContextHook);
}

export default function AuthContext({ children }) {
    const navigate = useNavigate();
    const { country, ipAddress } = useThemeContext();
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const [IsLogedIn, setIsLogedIn] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [isAuth, setisAuth] = useState(true);
    const [cred, setCred] = useState([]);
    const [apiLoader, setApiLoader] = useState(false);
    const [userId, setUserId] = useState("");

    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLogout = useCallback(async () => {
        setApiLoader(true);
        axios.post(API_URLs.logout, {
            refreshToken: localStorage.getItem('jwtOut')
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setNavigateLink('/login');
                navigate('/login');
                setIsLogedIn(false);
                setApiLoader(false);
                setisAuth(false);
                // handleShowModal(res?.data?.msg ? res?.data?.msg : 'Logged out successfully');
                localStorage.clear();
            })
            .catch((error) => {
                setApiLoader(false);
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occurred');
                setNavigateLink('');
            });
    }, [navigate]);

    const handleSignUp = useCallback(async (email, password, referralCode) => {
        setCred({ email, password, referralCode });
        try {
            let res = await axios.post(API_URLs.signUp, {
                email,
                password,
                ...(referralCode && { referralCode })
            });
            localStorage.setItem('jwt', res.data.tokens);
            setStep(step + 1);
        } catch (error) {
            setNavigateLink('');
            handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occurred");
        }
    }, [step]);

    const handleOtpVerification = async (otp) => {
        try {
            let res = await axios.post(API_URLs.signUpOtp, {
                token: localStorage.getItem("jwt"),
                otp: otp
            });
            localStorage.setItem('jwt', res.data.tokens.registerAcccessToken.token);
            handleShowModal(res?.data?.msg ? res?.data?.msg : 'Account Created Successfully');
            setNavigateLink('/signup/phone-verification');
            setStep(0);
        } catch (error) {
            setNavigateLink('');
            handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occurred");
        }
    };

    const handleLogin = async (email, password) => {
        setCred({ email, password });
        try {
            let res = await axios.post(API_URLs.logIn, {
                email: email,
                password: password,
            }, {
                headers: {
                    'device-address': country,
                    'device-type': browserName,
                    'ip-address': ipAddress
                }
            });
            if (res?.data?.isTwoFa) {
                localStorage.setItem('jwt', res.data?.responses?.token);
                setNavigateLink('/two-fa-login');
                if (res.data?.responses?.img) {
                    localStorage.setItem('qrcode', res.data?.responses?.img);
                }
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'OTP have send successfully on your Email/Phone');
            } else {
                localStorage.setItem('jwt', res.data?.tokens);
                setNavigateLink('/login/otp');
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'OTP have send successfully on your Email/Phone');
            }
        } catch (error) {
            handleShowModal(error?.response?.data?.message ? error.response?.data?.message : "Some Error occurred");
            setNavigateLink('');
        }
    };

    const handleLoginOTPVerification = async (otp) => {
        try {
            let res = await axios.post(API_URLs.loginVerify, {
                otp: otp,
                token: localStorage.getItem('jwt')
            });

            if (res?.data?.registrationComplete) {
                setUserId(res?.data?.user?._id);
                localStorage.setItem('jwt', res.data?.tokens?.access?.token);
                localStorage.setItem('jwtOut', res.data?.tokens?.refresh?.token);
                requestForToken();
                setUserDetails(res.data);
                setIsLogedIn(true);
                setisAuth(true);
                if (localStorage.getItem('pending-route')?.length > 0) {
                    navigate(localStorage.getItem('pending-route'));
                } else {
                    navigate('/dashboard/user-dashboard');
                }
            } else {
                localStorage.setItem('jwt', res.data.tokens.registerAcccessToken.token);
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Account Created Successfully');
                setNavigateLink('/signup/phone-verification');
                setStep(0);
            }
        } catch (error) {
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "OTP verification failed or may be expired");
            setNavigateLink('');
            setisAuth(false);
        }
    };

    const handleForgotPassword = async (email) => {
        try {
            let res = await axios.post(API_URLs.forgot, { email: email });
            handleShowModal(res?.data?.msg ? res?.data?.msg : "Please check your mail to Reset password");
            setNavigateLink('/login');
        } catch (error) {
            setNavigateLink('');
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "No users found with this email");
        }
    };

    const handleResetPassword = async (password, token) => {
        try {
            let res = await axios.post(API_URLs.reset + '?token=' + token, { password: password });
            handleShowModal(res?.data?.msg ? res?.data?.msg : 'Password Changed Successfully!!');
            setNavigateLink('/login');
        } catch (error) {
            setNavigateLink('');
            handleShowModal(error?.response?.data?.message ? error.response.data.message : "Some Error Occurred");
        }
    };

    const getUserDetails = () => {
        axios.get(API_URLs.getUser, {
            headers: {
                accept: 'application/json',
                "Authorization": `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoading(false);
                setIsLogedIn(true);
                setUserDetails(res.data);

                console.log("res data", res.data);
                localStorage.setItem('username', res?.data?.user?.nickNames)
                localStorage.setItem('email', res?.data?.user?.email)
                localStorage.setItem('mobile', res?.data?.user?.mobile)
                // localStorage
            })
            .catch((error) => {
                setIsLogedIn(false);
                setLoading(false);
            });
    };

    const handleVerifyTwoFa = async (otpValue) => {
        try {
            const res = await axios.post(API_URLs.verifyTwoFa, {
                token: localStorage.getItem('jwt'),
                otp: otpValue
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            });
            localStorage.setItem('jwt', res.data?.tokens?.access?.token);
            localStorage.setItem('jwtOut', res.data?.tokens?.refresh?.token);
            requestForToken();
            setUserDetails(res.data);
            localStorage.removeItem('qrcode');
            navigate('/dashboard/user-dashboard');
            setIsLogedIn(true);
            setisAuth(true);
        } catch (error) {
            toast.error(error.response?.data?.message ? error.response?.data?.message : "Some Error Occurred", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    const checkSession = useCallback(() => {
        const token = localStorage.getItem('jwtOut');
        if (!token) {
            setIsLogedIn(false);
            navigate('/login');
        }
    }, [navigate]);

    // useEffect(() => {
    //     const interval = setInterval(checkSession, 60000);
    //     return () => clearInterval(interval);
    // }, [checkSession]);

    useEffect(() => {
        getUserDetails();
    }, []);

    const exportableData = {
        handleLogout,
        getUserDetails,
        handleSignUp,
        handleOtpVerification,
        handleLogin,
        handleLoginOTPVerification,
        handleForgotPassword,
        handleResetPassword,
        handleVerifyTwoFa,
        IsLogedIn,
        isAuth,
        userDetails,
        loading,
        apiLoader,
        userId,
        step,
        setStep,
        showModal,
        handleCloseModal,
        navigateLink,
        modalData,
        cred
    };

    return (
        <AuthContextHook.Provider value={{ ...exportableData }}>
            {children}
        </AuthContextHook.Provider>
    );
}
