import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Reffer from './Reffer'
import '../ProfileNew/Profile.css';
import Security from './Security';
import ChangePassword from './ChangePassword';
import Kyclevel from './Kyclevel';
import Preference from './Preference';
import Notifications from './Notifications';
import PaymentMethodIndex from '../Profile/PaymentMethodIndex';
import Profile from '../ProfileNew/Index'
import { ProfileHeading } from '../Profile/StyledComponents';
import UserAct from './UserAct';
import styled from 'styled-components';
import { useState } from 'react';
const Div = styled.div`
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
`
export default function SettingsTabs() {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('profile');

    // const isTabSelected = location.hash.includes('profile')
    //     || location.hash.includes('security')
    //     || location.hash.includes('payment-method')
    //     || location.hash.includes('kyc-levels')
    //     || location.hash.includes('notifications')
    //     || location.hash.includes('preference')
    //     || location.hash.includes('your-reffers')

    useEffect(() => {
        const hash = location.hash.substring(1); // Remove the leading '#'
        if (hash) {
          setActiveTab(hash);
        }
      }, [location]);

    const tabData = [
        {
            name: 'Profile',
            hash: '#profile',
            hashName: 'profile',
            element: <Profile />
        },
        {
            name: 'Security',
            hash: '#security',
            hashName: 'security',
            element: <Div className='card p-3 p-md-4'>
                <ProfileHeading>Security</ProfileHeading>
                <ChangePassword />
                <Security />
                <UserAct />
            </Div>
        },
        {
            name: 'Payment Methods',
            hash: '#payment-method',
            hashName: 'payment-method',
            element: <PaymentMethodIndex />
        },
        {
            name: 'KYC Account Limit',
            hash: '#kyc-levels',
            hashName: 'kyc-levels',
            element: <Kyclevel />
        },
        {
            name: 'Notifications',
            hash: '#notifications',
            hashName: 'notifications',
            element: <Notifications />
        },
        {
            name: 'Preference',
            hash: '#preference',
            hashName: 'preference',
            element: <Preference />
        },
        {
            name: 'Rewards Program',
            hash: '#rewards-program',
            hashName: 'rewards-program',
            element: <Reffer />
        },
    ]

    const handleTabClick = (hashName) => {
        setActiveTab(hashName);
        navigate(`#${hashName}`);
    };

    return (
        <>
            <div className='tabs-navigator'>
                <ul className="navProfile nav-tabs d-flex" id="myTab" style={{overflowX: 'scroll'}}>
                    {
                        tabData.map((data) => {
                            return <li className="nav-item" key={data.hashName}>
                                <button
                                    // className={`nav-link ${isTabSelected ? location.hash.includes(data.hashName) ? 'active' : '' : 'active'}`}
                                    // id={`${data.hashName}-tab`}
                                    // data-bs-toggle="tab"
                                    // data-bs-target={data.hash}
                                    // type="button"
                                    // role="tab"
                                    // aria-controls={data.hashName}
                                    // aria-selected="true"
                                    // onClick={() => navigate(data.hash)}
                                    className={`nav-link ${activeTab === data.hashName ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => handleTabClick(data.hashName)}
                                >
                                    {data.name}
                                </button>
                            </li>
                        })
                    }
                </ul>
                <div className="tab-content" id="myTabContent">
                    {
                        tabData.map((data) => {
                            return <div
                                // className={`tab-pane fade py-3 px-2 ${isTabSelected ? location.hash.includes(data.hashName) ? 'show active' : '' : 'show'}`}
                                // id={data.hashName}
                                // role='tabpanel'
                                // aria-labelledby={`${data.hashName}-tab`}
                                key={data.hashName}
                                className={`tab-pane fade py-3 px-2 ${activeTab === data.hashName ? 'show active' : ''}`}
                                id={data.hashName}
                                role="tabpanel"

                            > {data.element} </div>
                        })
                    }
                </div>
            </div>
        </>
    )
}
